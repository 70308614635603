import { AppFactory } from './AppFactory';

AppFactory
  .init()
  .mount()
  .afterMount(() => {
    if(import.meta.env.DEV) {
      console.info('App running in Inertia context');
    }
  });
